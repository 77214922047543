<template>
  <el-dialog title="新建主应用" v-model="visible" @closed="handleClose" width="500px">
    <el-form :model="formData" ref="formRef" :rules="rules" label-width="100px" label-position="right">
      <el-form-item label="应用英文名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="应用中文名称" prop="description">
        <el-input v-model="formData.description"></el-input>
      </el-form-item>
      <el-form-item>
        <template v-slot:label>应用图标<i class="el-icon-question info-icon"></i></template>
        <el-upload
          class="avatar-uploader"
          :action="IMAGE_UPLOAD"
          accept=".jpg,.png,.jpeg"
          :show-file-list="false"
          :before-upload="beforeUpload"
          @success="handleUploadSuccess"
        >
          <i v-if="!imageUrl" class="el-icon-plus avatar-uploader-icon"></i>
          <img v-else :src="imageUrl" alt="" style="width: 110px; height: 110px" />
        </el-upload>
      </el-form-item>
      <el-form-item label="应用简介" prop="remark">
        <el-input type="textarea" rows="3" maxlength="255" show-word-limit v-model="formData.remark"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" :loading="submitting" @click="handleFormSubmit">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { ElMessage } from 'element-plus';
import { createManApp } from '@/api/app';

export default {
  name: 'ApplicationDialog',
  setup(props, { emit }) {
    const visible = ref(false);
    const submitting = ref(false);
    const formData = reactive({
      name: '',
      description: '',
      remark: '',
      thumbnail: '',
    });
    const imageUrl = ref('');
    const formRef = ref();
    const rules = {
      name: [
        { required: true, message: '请输入应用英文名称', trigger: 'blur' },
        {
          min: 3,
          max: 16,
          message: '应用英文名称长度在1到20个字符之间',
          trigger: 'blur',
        },
        {
          pattern: /^[a-z]+$/g,
          message: '该模块英文名仅可使用小写字母，请重新输入',
          trigger: 'blur',
        },
      ],
      description: [
        { required: true, message: '请输入应用中文名称', trigger: 'blur' },
        {
          min: 1,
          max: 20,
          message: '应用中文名称长度在1到20个字符之间',
          trigger: 'blur',
        },
      ],
    };

    const handleOpen = () => {
      visible.value = true;
    };
    const handleClose = () => {
      formRef.value.resetFields();
      visible.value = false;
    };

    const beforeUpload = (file) => {
      if (file.size > 1024 ** 2 * 3) {
        return ElMessage.warning('上传图片大小不能超过3M');
      }
    };

    const handleUploadSuccess = (res, file) => {
      if (res.code === 0 && res.data?.fileKey) {
        formData.thumbnail = res.data.fileKey;
        imageUrl.value = URL.createObjectURL(file.raw);
      } else {
        return ElMessage.error('上传失败，请重新上传！');
      }
    };

    const handleFormSubmit = () => {
      formRef.value.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        try {
          submitting.value = true;
          const { code } = await createManApp({ ...formData, envFlag: 'PROD' });
          if (code === 0) {
            ElMessage.success('成功新建主应用');
            emit('success-add');
          }
        } catch (e) {
          console.log(e);
        }
        handleClose();
        submitting.value = false;
      });
    };

    return {
      visible,
      submitting,
      handleOpen,
      formData,
      formRef,
      handleClose,
      rules,
      imageUrl,
      IMAGE_UPLOAD,
      beforeUpload,
      handleUploadSuccess,
      handleFormSubmit,
    };
  },
};
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: #409eff;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 115px;
  height: 85px;
  line-height: 85px;
  text-align: center;
}
</style>
