<template>
  <div class="app-arrange">
    <div class="app-arrange__header">
      <el-row style="margin-bottom: 10px">
        <el-col :span="6" style="text-align: left">
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="handleDialogOpen"
            style="width: 90px"
            v-if="isHasProdEnv && getShowBool('add')"
            >新建</el-button
          >
        </el-col>
        <el-col :offset="12" :span="6" style="text-align: right">
          <el-input
            style="max-width: 300px; margin-left: auto"
            placeholder="请输入主应用名称"
            suffix-icon="el-icon-search"
            @input="handleSearch"
            v-model="query.keyword"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="app-arrange__body" style="margin-top: 20px">
      <list-wrap
        :handleCreate="handleDialogOpen"
        :loading="loading"
        :empty="total === 0"
        :hasCreateAuth="isHasProdEnv && getShowBool('add')"
        create-label="新建"
      >
        <p class="sa-list-wrap__empty" v-if="!isHasProdEnv">暂无生产环境</p>
        <div class="list-item__cards">
          <template v-for="item in list" :key="item.id">
            <slot :data="item" name="card">
              <ApplicationCard
                env="PROD"
                env-name="prod"
                :data="item"
                :env-id="prod.id"
                :show-status="true"
                :appType="main"
                :onClick="handleAppClick"
                :has-detail-auth="getShowBool('selectDetail')"
              ></ApplicationCard>
            </slot>
          </template>
        </div>
        <packaged-pagination
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="total"
          layout="sizes, prev, pager, next, jumper"
          v-if="list.length"
        ></packaged-pagination>
      </list-wrap>
    </div>
  </div>
  <application-dialog ref="refDialog" env="PROD" @success-add="fetchList"></application-dialog>
</template>
<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { getShowBool } from '@/utils/permission-show-module';
import { ElMessage } from 'element-plus';
import { getAppsMain } from '@/api/app';
import ApplicationCard from '../../application-module/components/ApplicationCard';
import ApplicationDialog from './components/ApplicationDialog';
import { getEnvList } from '@/api/project';
import { ENV_ENUM } from '../../env/type';
import { removeEnvStorage, setEnvStorage } from '@/utils/env-storage';
import { useSaList } from '@/shared/hooks/list';

export default {
  components: {
    ApplicationDialog,
    ApplicationCard,
  },
  setup() {
    const refDialog = ref();
    const prod = ref(null);
    const isHasProdEnv = ref(false);
    const timer = ref(null);
    const handleDialogOpen = () => {
      refDialog.value.handleOpen();
    };

    const { query, total, list, loading, fetchList, handleSearch, handlePageSizeChange, handlePageChange } = useSaList(
      async (query, useLoading = true) => {
        if (!isHasProdEnv.value) {
          return;
        }
        if (useLoading) {
          loading.value = true;
        }
        try {
          const { data, code, message } = await getAppsMain({
            ...query,
            env: ENV_ENUM.PROD,
            serviceVersionType: 1,
          });
          if (code === 0) {
            const { rows, count } = data;
            list.value = rows;
            total.value = count;
            timer.value = setTimeout(() => {
              fetchList(false);
            }, 5000);
          } else {
            ElMessage.error(message);
          }
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );
    const hasProdEnv = async () => {
      try {
        loading.value = true;
        const { data: prodData } = await getEnvList({ env: ENV_ENUM.PROD });
        if (prodData.length === 0) {
          isHasProdEnv.value = false;
          loading.value = false;
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        prod.value = prodData[0];
        isHasProdEnv.value = true;
        setEnvStorage(prod.value.id);
        await fetchList();
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const handleAppClick = (payload) => {
      payload.router.push({
        path: `/apps-arrange/apps/prod/${payload.envId}/${payload.id}`,
        query: {
          pp: query.page,
        },
      });
    };

    onMounted(() => {
      hasProdEnv();
    });
    onBeforeUnmount(() => {
      removeEnvStorage();
      clearTimeout(timer.value);
    });

    return {
      getShowBool,
      prod,
      refDialog,
      query,
      total,
      list,
      loading,
      fetchList,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      handleDialogOpen,
      hasProdEnv,
      isHasProdEnv,
      handleAppClick,
    };
  },
};
</script>

<style>
.list-item__cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 284px);
  grid-gap: 20px;
  margin: 0 0 20px 0;
  padding: 20px;
  list-style: none;
}
</style>
